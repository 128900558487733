class Menu {
    constructor() {
        this.menuToggle = document.querySelector('.js-navigation__toggle');
        this.html = document.getElementsByTagName('html')[0];
        this.events();

    }

    toggle = () => {
        this.html.classList.toggle('has-navigation-open');
    }

    close = () => {
        this.html.classList.remove('has-navigation-open');
    }

    keyboardClose = (evt) => {
        evt = evt || window.event;
        let isEscape = false;
        if ("key" in evt) {
            isEscape = (evt.key === "Escape" || evt.key === "Esc");
        } else {
            isEscape = (evt.keyCode === 27);
        }
        if (isEscape) {
            this.close();
        }
    }

    events = () => {
        this.menuToggle.addEventListener('click', this.toggle);
        document.addEventListener('keypress', this.keyboardClose);
    }
}
export default Menu
