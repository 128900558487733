class CategoryFilters {
  constructor(filters) {
    this.setDomElements(filters);
    this.setInitialState();
    this.setFilters();
  }

  setDomElements(filters) {
    this.$filtersContainer = filters;
    this.$filters = this.$filtersContainer.querySelectorAll("li");
    this.$grid = document.querySelector(".grid");
  }

  filterCategory(category) {
    if (category === "all") {
      let current = document.querySelector(".filters .current");
      current.classList.remove("current");
      // show all hidden boxes

      let boxes = this.$grid.querySelectorAll(".invis");
      boxes.forEach((box) => {
        box.classList.toggle("invis");
      });
    } else {
      // hide visible boxes
      let boxesNotInCategory = this.$grid.querySelectorAll(
        `.grid-item:not(.${category}):not(.invis)`
      );
      boxesNotInCategory.forEach((box) => {
        box.classList.toggle("invis");
      });
      // show hidden boxes
      var boxesInCategory = this.$grid.querySelectorAll(
        `.grid-item.${category}.invis`
      );
      boxesInCategory.forEach((box) => {
        box.classList.toggle("invis");
      });
    }
  }

  setInitialState() {
    if (window.location.hash) {
      var hash = window.location.hash;
      var current = document.querySelector(".filters .current");
      current.classList.remove("current");

      var newCurrent = document.querySelector(`.filters a[href='${hash}']`);
      if (newCurrent.parentNode) {
        newCurrent.parentNode.classList.add("current");
      }

      // If parent is a list element, add .current class to it
      if (newCurrent.parentNode.parentNode.parentNode.tagName == "LI") {
        newCurrent.parentNode.parentNode.parentNode.classList.add("current");
      }

      hash = hash.slice(1);
      this.filterCategory(hash);
    } else {
      var $all = document.querySelector(".filters .all");
      $all.classList.add("current");
    }
  }

  setFilters() {
    this.$filters.forEach((item) => {
      let $link = item.querySelector("a");
      $link.addEventListener("click", (event) => {
        let url = event.target.getAttribute("href");
        let hash = url.substring(url.indexOf("#") + 1);

        this.filterCategory(hash);
        console.log(hash);

        // Remove .current from children
        document
          .querySelectorAll(".filters .current li")
          .forEach((item, index) => {
            item.classList.remove("current");
          });

        // Remove .current from siblings
        for (let sibling of event.target.parentNode.parentNode.children) {
          sibling.classList.remove("current");
        }

        // add .current
        event.target.parentNode.classList.add("current");
      });
    });
  }
}

export default CategoryFilters;
