"use strict";

import "lazysizes";
import "flickity/dist/flickity.min.css";
import Flickity from "flickity";
import "flickity-imagesloaded";
import "/src/css/index.scss";

import Menu from "./modules/Menu";
import CategoryFilters from "./modules/CategoryFilters";
import ScrollToAnchorSmoothly from "./modules/ScrollToAnchorSmoothly";

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}

// Generate random number within a range
function getRandomIntInclusive(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Check if elements are in view
function elementsInView(e) {
  // let windowHeight;
  let elements = document.querySelectorAll(e);

  function checkPosition() {
    elements.forEach((element) => {
      let positionFromTop = element.getBoundingClientRect().top;

      if (positionFromTop - window.innerHeight <= 0) {
        element.classList.add("in-view");
      }
    });
  }

  window.addEventListener("scroll", checkPosition);

  checkPosition();
}

function setupSliders() {
  let sliders = document.querySelectorAll(".slider");

  sliders.forEach(function (slider, index) {
    let t = getRandomIntInclusive(4000, 7000);

    let flkty = new Flickity(slider, {
      cellAlign: "left",
      contain: true,
      imagesLoaded: true,
      lazyLoad: 2,
      wrapAround: true,
      prevNextButtons: false,
      autoPlay: t,
      draggable: true,
      pageDots: false,
    });
  });
}

function projectSlider() {
  if (document.querySelector(".project-gallery-slider")) {
    let flkty = new Flickity(".project-gallery-slider", {
      cellAlign: "left",
      contain: true,
      imagesLoaded: true,
      //lazyLoad: 2,
      pageDots: true,
      draggable: true,
    });
  }
}

function headline() {
  if (document.querySelector(".headline")) {
    let headline = document.querySelector(".headline");
    let closeButton = document.querySelector(".headline-close");
    closeButton.addEventListener("click", function (event) {
      event.preventDefault();
      headline.classList.add("headline--hidden");
    });
  }
}

function enquiryType() {
  let dropdown = document.querySelector("#enquiry_type");
  let professional = document.querySelector("#professional");
  dropdown.addEventListener("input", function (event) {
    console.log("!");
    if (event.target.value === "professional") {
      professional.style.display = "block";
    } else {
      professional.style.display = "none";
    }
  });
}

class nigelcoates {
  constructor() {
    this.init();
  }

  init() {
    setupSliders();
    elementsInView(".slider,.project-gallery-wrap");
    headline();
    projectSlider();

    if (document.querySelector("#enquiry_type")) {
      enquiryType();
      //validateEnquiry();
    }

    document.documentElement.className =
      document.documentElement.className.replace(/\bno-js\b/g, "") + " js ";

    const menu = new Menu();

    const categoryFiltersModules = document.querySelectorAll(".js-filters");
    if (!!categoryFiltersModules) {
      categoryFiltersModules.forEach(
        (categoryFiltersModule) => new CategoryFilters(categoryFiltersModule)
      );
    }

    const anchors = document.querySelectorAll('a[href*="#"]:not([href="#"])');
    if (!!anchors) {
      anchors.forEach((anchor) => new ScrollToAnchorSmoothly(anchor));
    }
  }
}

(function () {
  document.addEventListener("touchstart", function () {}, true);
  new nigelcoates();
})();
