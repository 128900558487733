import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

class ScrollToAnchorSmoothly {
  constructor(anchor) {
    this.$anchor = anchor;
    this.setSmoothScrolling();
  }

  setSmoothScrolling() {
    
    this.$anchor.addEventListener("click", (event) => {
      // Checks if link is on the same page
      if (
        this.pathname == window.location.pathname &&
        this.protocol == window.location.protocol &&
        this.host == window.location.host
      ) {
        event.preventDefault();
        let url = this.getAttribute("href");
        let hash = url.substring(url.indexOf("#") + 1);
        let destinationElement = document.getElementById(hash);
        window.scroll({
          behavior: "smooth",
          left: 0,
          top: destinationElement.offsetTop,
        });
      }
    });
  }
}

export default ScrollToAnchorSmoothly;
